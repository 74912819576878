import React, { useEffect, useState } from "react"
import { Router } from "@reach/router"
import ErrorPage from "./404"
import { useAuth0 } from "@auth0/auth0-react"
import { Loading } from "../components/loading"
import FaqDetail from "./faq/detail"
import Faq from "./faq/faq"
import { fetch_get } from "../utils/fetch"
import { Error } from "../components/error"

const FAQ = () => {
  const { isLoading, error } = useAuth0()

  if (isLoading) {
    return <Loading />
  }

  return (
    <>
      {error && <Error message={error.message} />}
      <Router>
        <Faq path="/faq" />
        <FaqDetail path="/faq/:id" />
        <ErrorPage default />
      </Router>
    </>
  )
}

export default FAQ
