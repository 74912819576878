import React, { useEffect, useState } from "react"
import { Col } from "reactstrap"
import Layout from "../../components/layout/layout"
import Content from "../../components/content/content"
import Header from "../../components/header/header"
import { useUserOrgs } from "../../hooks/use-user"
import { Loading } from "../../components/loading"
import { fetch_get } from "../../utils/fetch"
import { checkPassword } from "../../utils/common"
import { PropagateLoader } from "react-spinners"
import { FaArrowAltCircleLeft } from "react-icons/fa"
import { Link } from "gatsby"
import { useAuth0 } from "@auth0/auth0-react"

const FaqDetail = props => {
  const { loading } = useUserOrgs()
  const { isAuthenticated, user } = useAuth0()
  const [pageLoading, setPageLoading] = useState(true)
  const [detail, setDetail] = useState({})
  const [errorMessage, setErrorMessage] = useState("")

  useEffect(() => {
    if (!loading) {
      if (checkPassword(isAuthenticated, user)) return;
      fetch_get(`faqs/${props.id}`, false)
        .then(response => {
          setDetail(response[0] || {})
          setPageLoading(false)
        })
        .catch(error => {
          console.log(error)
          setErrorMessage("An error occurred while getting the detail of faq.")
          setPageLoading(false)
        })
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading])

  if (loading) {
    return <Loading />
  }

  return (
    <Layout pageTitle="FAQ Detail">
      <Header contentPage={true} className="contentPage">
        <h1 className="display-4 font-weight-bold">
          <Link to={`/faq/`}>
            <FaArrowAltCircleLeft className="goBackIcon" />
          </Link>
          {errorMessage ? "Error" : detail.title}
        </h1>
      </Header>
      <Content>
        {pageLoading ? (
          <Col className="d-flex justify-content-center align-items-center">
            <PropagateLoader
              className="align-self-center"
              size={20}
              color={"#9c3"}
              loading={true}
            />
          </Col>
        ) : errorMessage ? (
          <p>{errorMessage}</p>
        ) : (
          <>
            <div dangerouslySetInnerHTML={{ __html: detail.content }}></div>
          </>
        )}
      </Content>
    </Layout>
  )
}

export default FaqDetail
